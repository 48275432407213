<template>
  <el-container>
    <el-main :model="form">
      <el-form ref="form" :model="form" label-width="140px" :rules="rules">
        <div class="item">
          <el-form-item label="优惠券名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <span class="sp">名称不超过15个字，中文</span>
        </div>
        <div class="item">
          <el-form-item label="优惠券类型：" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio :label="1">满减券</el-radio>
              <el-radio :label="2">立减券</el-radio>
              <el-radio :label="3">折扣券</el-radio>
              <el-radio :label="4">CDK优惠券</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="item Full-reduction" v-show="form.type == 1 || form.type == 4">
          <span style="width: 120px"></span>
          <div class="reduction-box">
            <span style="margin-right: 10px">消费满</span>
            <el-input type="number" :min="0" v-model="form.consume_full">
              <template slot="append">元</template>
            </el-input>
            <span style="margin: 0 10px">减</span>
            <el-input type="number" :min="0" v-model="form.subtract">
              <template slot="append">元</template>
            </el-input>
            <span style="margin-left: 10px">运费除外</span>
          </div>
        </div>
        <div class="item Full-reduction" v-show="form.type == 3">
          <span style="width: 120px"></span>
          <div class="reduction-box">
            <span style="margin-right: 10px">消费满</span>
            <el-input type="number" :min="0" v-model="form.consume_full">
              <template slot="append">元</template>
            </el-input>
            <span style="margin: 0 10px">折扣</span>
            <el-input type="number" :min="0" v-model="form.discount">
              <template slot="append">折</template>
            </el-input>
          </div>
        </div>
        <div class="item Full-reduction" v-show="form.type == 2">
          <span style="width: 120px"></span>
          <div class="reduction-box">
            <span style="margin-right: 10px">无门槛，消费立减</span>
            <el-input type="number" :min="0" style="width: 460px" v-model="form.subtract">
              <template slot="append">元</template>
            </el-input>
          </div>
        </div>
        <div class="item">
          <el-form-item label="领券时间：" prop="activity_start_time">
            <el-date-picker v-model="receiveTime" type="daterange" range-separator="~" @change="getReceiveTime" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
        </div>
        <div class="item effective">
          <el-form-item label="优惠券有效期：" prop="time_type">
            <el-radio-group v-model="form.time_type">
              <el-radio :label="1">固定时间</el-radio>
              <el-radio :label="2">领取后N天内</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="form.time_type == 1" label="" prop="start_time">
            <el-date-picker v-model="effectiveTimeVal" type="daterange" range-separator="~" @change="getEffectiveTimeVal" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item v-show="form.time_type == 2" label="" prop="days">
            <el-input v-model="form.days">
              <template slot="append">天内</template>
            </el-input>
          </el-form-item>
        </div>
        <div class="item" v-if="form.type == 4">
          <el-form-item label="CDK兑换码导入：" prop="file_name">
            <el-input class="upload" style="width: 410px" v-model="form.file_name" disabled>
              <!-- <el-button slot="append" @click="seeCdk">
                {{ form.id ? '查看' : '导入' }}
                <input v-if="!form.id" type="file" ref="inputer" id="upload" @change="getFile" />
              </el-button> -->
            </el-input>
            <el-button class="import" type="primary">
              导入
              <input type="file" ref="inputer" id="upload" @change="getFile" />
            </el-button>
            <el-button type="primary" v-if="id || form.cdkey.length" @click="seeCdk">查看</el-button>
          </el-form-item>
          <el-link style="margin-left: 10px; color: #409eff" href="https://boweisou.oss-cn-shenzhen.aliyuncs.com/journalism/CDK%E5%AF%BC%E5%85%A5%E8%A1%A8.xlsx">下载CDK兑换码模板</el-link>
        </div>
        <div class="item">
          <el-form-item label="发放数量：" prop="number">
            <el-input type="number" :disabled="form.type == 4 ? true : false" :min="0" v-model="form.number">
              <template slot="append">张</template>
            </el-input>
          </el-form-item>
        </div>
        <div class="item" v-if="form.type == 4">
          <el-form-item label="京东店铺ID：" prop="shop_id">
            <el-input type="text" :rows="5" placeholder="请输入内容" v-model="form.shop_id"></el-input>
          </el-form-item>
        </div>
        <div class="item" v-if="form.type != 4">
          <el-form-item label="用户资格：">
            <el-select v-model="form.level_id" style="width: 100%" placeholder="请选择">
              <el-option v-for="item in userGrade" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="item" v-if="form.type == 4">
          <el-form-item label="关联活动：">
            <el-select style="width: 100%" v-model="form.activity_id" placeholder="请选择">
              <el-option v-for="item in activity" :key="item.id" :label="item.activity_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="每人限领：" prop="each_limit">
            <el-input type="number" :min="0" v-model="form.each_limit">
              <template slot="append">张</template>
            </el-input>
          </el-form-item>
        </div>
        <div class="item store">
          <el-form-item label="关联店铺：">
            <el-tag color="#fff" :key="tag.id" v-for="tag in store" closable :disable-transitions="false" @close="removeStore(tag)">
              {{ tag.store_name }}
            </el-tag>
            <p @click="showAddStore = !0">+设置关联店铺</p>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="状态：">
            <el-radio-group v-model="form.status">
              <el-radio :label="1">正常</el-radio>
              <el-radio :label="0">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div class="item" v-if="form.type == 4">
          <el-form-item label="优惠券说明：">
            <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.details"></el-input>
          </el-form-item>
        </div>
        <Preservation @preservation="submit">
          <router-link to="/extension/couponList" slot="return" style="margin-right: 10px">
            <el-button>返回</el-button>
          </router-link>
        </Preservation>
      </el-form>
    </el-main>
    <el-dialog title="CDK码查看" :visible.sync="showCdk" width="700px">
      <el-table :data="is_local ? cdkeyInfo.slice((page - 1) * rows, page * rows) : cdkeyInfo" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="user_name" label="序号" width="80" align="center">
          <template v-slot="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="cdkey" label="CDK码" align="center"></el-table-column>
      </el-table>
      <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-dialog>
    <AddStroe @getStore="getStore" @closeAddStore="closeAddStore" :is_show="showAddStore"></AddStroe>
  </el-container>
</template>

<script>
import AddStroe from '@/views/goods/components/AddStore';
import Preservation from '@/components/preservation';
import xlsx from 'xlsx';
import Paging from '../../components/paging.vue';
import _ from 'lodash';
export default {
  components: {
    AddStroe,
    Preservation,
    Paging,
  },
  data() {
    return {
      id: '',
      showAddStore: !1,
      effectiveTimeVal: '',
      receiveTime: '',
      store: [],
      userGrade: [],
      activity: [],
      page: 1,
      rows: 5,
      cdkeyInfo: [],
      total_number: 0,
      showCdk: !1,
      is_local: !0,
      form: {
        name: '', //名称
        type: 1, //优惠券类型，1满减券，2立减券，3满折券
        consume_full: '', //消费满
        subtract: '', //立减
        discount: '', //	折扣
        activity_start_time: '', //活动开始时间
        activity_end_time: '', //活动结束时间
        time_type: 1, //有效期类型，1固定时间，2领取后N天内
        start_time: '', //有效开始时间
        end_time: '', //	有效结束时间
        days: '', //有效天数
        level_id: 0, //会员级别ID
        number: '', //发放数量
        each_limit: '', //每人限领
        status: 1, //状态，0停用，1正常
        store_id: [], //关联门店id数组([1,2])，不关联空
        cdkey: [], //CDK兑换码
        activity_id: '', //活动id
        file_name: '',
        details: '', //优惠券说明
        shop_id: '', //京东店铺id
      },
      rules: {
        name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
        activity_start_time: [{ required: true, message: '请填写领券时间', trigger: 'blur' }],
        start_time: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
        time_type: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
        days: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
        number: [{ required: true, message: '请填写发放数量', trigger: 'blur' }],
        each_limit: [{ required: true, message: '请填写限领数量', trigger: 'blur' }],
        type: [{ required: true, message: '', trigger: 'blur' }],
        file_name: [{ required: true, message: '请上传CDK兑换码', trigger: 'blur' }],
        shop_id: [{ required: true, message: '请填写京东店铺ID', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.$axios.post(this.$api.user.CardLevel).then(res => {
      if (res.code == 0) {
        this.userGrade = res.result.list;
        this.userGrade.unshift({
          id: 0,
          title: '所有用户',
        });
      }
    });
    this.getActivity();
    if (this.$route.query.id) {
      let utils = this.$store.state.utils;
      utils.page = Number(this.$route.query.page);
      utils.rows = Number(this.$route.query.rows);
      utils.is_record = !0;
      this.id = this.$route.query.id;
      this.couponInfo();
    }
  },
  methods: {
    //处理编辑信息
    couponInfo() {
      this.$axios
        .post(this.$api.push.couponInfo, {
          id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            let couponInfo = res.result;
            let store_id = [];
            for (let i in couponInfo.store_id) store_id.push(couponInfo.store_id[i].id);
            this.effectiveTimeVal = [new Date(couponInfo.start_time * 1000), new Date(couponInfo.end_time * 1000)];
            this.receiveTime = [new Date(couponInfo.activity_start_time * 1000), new Date(couponInfo.activity_end_time * 1000)];
            this.store = couponInfo.store_id;
            this.form = couponInfo;
            this.is_local = !1;
            this.form.store_id = [];
          }
        });
      this.getCdk();
    },
    getEffectiveTimeVal(val) {
      this.form.start_time = val[0].getTime() / 1000;
      this.form.end_time = val[1].getTime() / 1000;
    },
    getReceiveTime(val) {
      this.form.activity_start_time = val[0].getTime() / 1000;
      this.form.activity_end_time = val[1].getTime() / 1000;
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      if (!this.is_local) this.getCdk();
    },
    seeCdk() {
      this.showCdk = !0;
    },
    getCdk() {
      this.$axios
        .post(this.$api.push.cdkeyInfo, {
          page: this.page,
          rows: this.rows,
          id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
            this.cdkeyInfo = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //获取门店
    getStore(val) {
      this.showAddStore = !1;
      this.store = val;
    },
    //获取活动
    getActivity() {
      this.$axios
        .post(this.$api.sports.activityList, {
          rows: 1000,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
            this.activity = res.result.list;
          }
        });
    },
    //获取文件
    getFile() {
      let files = this.$refs.inputer.files;
      let that = this;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert('上传格式不正确，请上传xls或者xlsx格式');
      }
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; //取第一张表，wb.SheetNames[0]是获取Sheets中第一个Sheet的名字
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容，wb.Sheets[Sheet名]获取第一个Sheet的数据
          const excellist = []; //清空接收数据
          that.cdkeyInfo = [];
          that.is_local = !0;
          //编辑数据
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]['CDK兑换码']);
            that.cdkeyInfo.push({
              cdkey: ws[i]['CDK兑换码'],
            });
          }
          if (that.id) that.form.cdkey_edit = 1;
          that.total_number = excellist.length;
          that.form.cdkey = excellist;
          that.form.number = excellist.length;
          console.log('读取结果', excellist); // 此时得到的是一个内容是对象的数组需要处理
        } catch (e) {
          return alert('读取失败!');
        }
      };
      fileReader.readAsBinaryString(files[0]);
      let input = document.getElementById('upload');
      this.form.file_name = files[0].name;
      input.value = '';
    },
    removeStore(tag) {
      this.store.splice(this.store.indexOf(tag), 1);
    },
    closeAddStore() {
      this.showAddStore = !1;
    },
    submit() {
      try {
        let form = _.cloneDeep(this.form);
        if (!form.name) throw '请填写优惠券名称';
        if (form.type == 1) {
          if (!form.consume_full) throw '请填写消费满多少';
          if (!form.subtract) throw '请填写立减多少';
        } else if (form.type == 2) {
          if (!form.subtract) throw '请填写立减多少';
        } else if (form.type == 3) {
          if (!form.consume_full) throw '请填写消费满多少';
          if (!form.discount) throw '请填写折扣多少';
        } else {
          if (!form.consume_full) throw '请填写消费满多少';
          if (!form.subtract) throw '请填写立减多少';
          if (!form.file_name && !this.id) throw '请上传CDK兑换码';
          if (!form.details) throw '请填写优惠券说明';
          if (!form.shop_id) throw '请填写京东店铺ID';
          if (this.id && !form.cdkey_edit) delete form.cdkey;
          form.level_id = 0;
        }
        if (!this.receiveTime) throw '请填写领券时间';
        if (form.time_type == 1) {
          if (!this.effectiveTimeVal) throw '请填写优惠券有效期';
        } else {
          if (!form.days) throw '请填写优惠券有效期';
        }
        if (!form.number) throw '请填写发放数量';
        if (!form.each_limit) throw '请填写限领数量';
        if (this.store.length) {
          for (let i in this.store) form.store_id.push(this.store[i].id);
        }
        let url = form.id ? this.$api.push.couponEdit : this.$api.push.addCoupon;
        this.$axios.post(url, form).then(res => {
          if (res.code == 0) {
            this.$message({
              message: form.id ? '编辑成功' : '添加成功',
              type: 'success',
            });
            this.$router.push('/extension/couponList');
          } else {
            this.$message.error(res.msg);
          }
        });
      } catch (e) {
        this.$message({
          message: e,
          type: 'warning',
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
  .el-main {
    background: #fff;
    padding: 50px 70px;
    .import {
      position: relative;
      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
      }
    }
  }
  .el-form {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .sp {
        font-size: 14px;
        margin-left: 10px;
      }
    }
    .Full-reduction {
      .reduction-box {
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
        }
        .el-input {
          width: 247px;
        }
      }
    }
    .store {
      .el-form-item {
        align-items: center;
      }
      .el-tag {
        margin-right: 10px;
      }
      p {
        cursor: pointer;
        color: #409eff;
      }
    }
    .effective {
      flex-direction: column;
      align-items: flex-start;
    }
    .el-form-item {
      width: 700px;
      margin: 0;
    }
  }
}
</style>
